import "core-js/modules/es.array.push.js";
import $ from "jquery";
import NotificationsModal from "./NoficationModal";
export default {
  name: "admin-notifications",
  components: {
    NotificationsModal
  },
  data() {
    return {
      loading: false,
      payload: {
        type: ""
      }
    };
  },
  methods: {
    navigate(route) {
      if (this.$route.path == route) return;
      this.$router.push(route).catch(err => {});
    },
    openNotificationModal() {
      let element = this.$refs.notificationModal.$el;
      $(element).modal("show");
    },
    openModal() {
      this.payload = {
        type: "new"
      };
      this.openNotificationModal();
    },
    closeNotifcationModal() {
      let element = this.$refs.notificationModal.$el;
      $(element).modal("hide");
    }
  },
  computed: {
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    }
  }
};